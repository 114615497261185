import React from 'react';
import './style.scss';

class AboutSection extends React.Component {
  render() {
    return (
      <div className="about">
       
        <h4>YWCA Metro Vancouver thanks the many contributors to the FASD Dictionary, including:</h4>
        <div className="column-wrapper">
          <div className="about-column">
            <ul>
              <li><a target="_blank" rel="noreferrer noopener" href="https://interprofessional.ubc.ca/initiatives/fasd2020/committee/alc/">The FASD Adult Leadership Committee</a> and Jan Lutke</li>
              <li><a target="_blank" rel="noreferrer noopener" href="http://www.reciprocalconsulting.ca/">Reciprocal Consulting</a></li>
              <li><a target="_blank" rel="noreferrer noopener" href="https://www.cheos.ubc.ca/">The Centre for Advancing Health Outcomes</a></li>
              <li><a target="_blank" rel="noreferrer noopener" href="https://rethinkcanada.com">Rethink Communications</a></li>
            </ul>
          </div>
          <div className="about-column">
            <ul>
              <li>Dr. Christine Loock, Developmental Pediatrician, BC Children's Hospital; Professor, UBC Department of Pediatrics</li>
              <li> Dr. Gurpreet (Preety) Salh MD FRCPC; Developmental Pediatrician, Sunny Hill Health Centre for Children</li>
              <li><a target="_blank" rel="noreferrer noopener" href="https://ywcavan.org/programs/crabtree-corner">YWCA Crabtree Corner</a> Grandparent's Group</li>
              <li>YWCA Crabtree Corner Key Workers (Special thanks to Wanda Pelletier and April Beaver)</li>
            </ul>
          </div>
          <div className="about-column rounded">
            <p>The FASD Dictionary is part of YWCA Metro Vancouver's United Families for the Future project. Funded by <a target="_blank" rel="noreferrer noopener" href="https://www.canada.ca/en/public-health.html">Public Health Agency of Canada</a>, United Families for the Future is a three-year applied research project that aims to combat stigma and help reduce the prevalence of Fetal Alcohol Spectrum Disorder. Guided by the perspectives of individuals with lived experience, the FASD Dictionary provides the public with information and&nbsp;resources.</p>
          </div>
        </div>
      </div>
    )
  }
}
export default AboutSection;