import React from 'react';
import './style.scss';

import { Fade } from "react-awesome-reveal";

import BtnPlus from '../Btns/BtnPlus/BtnPlus';
import BtnPlay from '../Btns/BtnPlay/BtnPlay';

import ModalItem from '../../components/ModalItem/ModalItem';

class GridSingleItem extends React.Component {
  render() {
    return (
      <>
      <div className={`single ${ this.props.gridClass }`}>
        <a href={this.props.linkhref} data-src={this.props.dataSrc} data-fancybox="fasddictionary" data-slug={this.props.letter} data-type="clone" className="letter-link">
          <div className="letter-wrapper">
            <Fade triggerOnce="true">
            <div className="letter">{this.props.letter}</div>
            <span className="is-for">is for</span>
            <span className="meaning">{this.props.meaning}</span>
            </Fade>
          </div>
         {this.props.video ? <BtnPlay /> : <BtnPlus />}
         {this.props.video ? <div className="vid-host-name">{this.props.name}</div> : null}
        </a>
      </div>

      <ModalItem id={this.props.dataSrc} backgroundColor={this.props.bgColor} contentColor={this.props.textColor} letter={this.props.letter} standsfor={this.props.meaning} featureName={this.props.featureName} featureTitle={this.props.featureTitle} featureIs={this.props.featureIs} static={this.props.video} youtubeId={this.props.videoId} />
      
      </>
    )
  }
}
export default GridSingleItem;