import React from 'react';
import './style.scss';
//import {Fancybox} from "@fancyapps/ui";

//import { Helmet } from 'react-helmet';

import {data} from './data';
//import Meta from '../../components/Meta/Meta';
//import { Fade, Slide } from "react-awesome-reveal";

// import BtnModalShare from '../Btns/BtnModalShare/BtnModalShare';
// import BtnShare from '../../components/Btns/BtnShare/BtnShare';
// import BtnFacebook from '../../components/Btns/BtnFacebook/BtnFacebook';
// import BtnTwitter from '../../components/Btns/BtnTwitter/BtnTwitter';
// import BtnLink from '../../components/Btns/BtnLink/BtnLink';
// import BtnClose from '../../components/Btns/BtnClose/BtnClose';


import imageA from '../../assets/images/A.jpg';
import imageB from '../../assets/images/B.jpg';
import imageC from '../../assets/images/C.jpg';
import imageD from '../../assets/images/D.jpg';
import imageE from '../../assets/images/E.jpg';
import imageG from '../../assets/images/G.jpg';
import imageH from '../../assets/images/H.jpg';
import imageJ from '../../assets/images/J.jpg';
import imageL from '../../assets/images/L.jpg';
import imageM from '../../assets/images/M.jpg';
import imageN from '../../assets/images/N.jpg';
import imageO from '../../assets/images/O.jpg';
import imageP from '../../assets/images/P.jpg';
import imageQ from '../../assets/images/Q.jpg';
import imageS from '../../assets/images/S.jpg';
import imageT from '../../assets/images/T.jpg';
import imageU from '../../assets/images/U.jpg';
import imageV from '../../assets/images/V.jpg';
import imageW from '../../assets/images/W.jpg';
import imageX from '../../assets/images/X.jpg';
import imageZ from '../../assets/images/Z.jpg';


class ModalItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSocialToggle : false,
    }
    this.toggleModalSocialButton = this.toggleModalSocialButton.bind(this);
  }

  toggleModalSocialButton() {
    console.log('tsaf');
    this.setState(prevState => ({ modalSocialToggle: !prevState.modalSocialToggle }))
  }


  render() {

    const images = {
      A:imageA,
      B:imageB,
      C:imageC,
      D:imageD,
      E:imageE,
      G:imageG,
      H:imageH,
      J:imageJ,
      L:imageL,
      M:imageM,
      N:imageN,
      O:imageO,
      P:imageP,
      Q:imageQ,
      S:imageS,
      T:imageT,
      U:imageU,
      V:imageV,
      W:imageW,
      X:imageX,
      Z:imageZ
    };
// F, I, K, R, Y letters are videos
    let currentLetter = this.props.letter;
    // assigns #'s to each letter. a = 0, b = 1, c = 2, etc
    const currentLetterSpot = parseInt(currentLetter.charCodeAt(0) -65);

    // const copyText = data.map(paragraph => 
    //   <p key={paragraph.letter}>{paragraph.copy}</p>)
    //   console.log('copy' , copyText);


    //const copyText = data.filter(copydata => copydata.letter === currentLetter).map(copydata => <div key={copydata.letter}>{copydata.copy}</div>);


    //console.log('images' , images[currentLetter]);
    
    // get the copy
    const copyTextItems = Object.keys(data).map((key) => data[key]);
   
   
   
    return ( 
     
      <>
       <div className="modal-item" id={(this.props.id).substring(1)} style={{display:"none", backgroundColor:this.props.backgroundColor}}>
          <div className="corner-wrapper">
            <span className="corner-letter" style={{color:this.props.backgroundColor, backgroundColor: this.props.contentColor}}>{this.props.letter}</span>
            {/* {this.state.modalSocialToggle === true ? <Slide direction="right" triggerOnce="true"><Fade delay="30" triggerOnce="true"><span className="social-btns"><BtnFacebook fblink="https://www.facebook.com/sharer.php?u=https://fasddictionary.ywcavan.org/" /><BtnTwitter twitlink="https://twitter.com/intent/tweet?url=https://fasddictionary.ywcavan.org/&text=" /><BtnLink clickModalHandler={this.copyUrl} /> <BtnClose clickModalHandler={this.toggleSocialButton} /></span></Fade></Slide> : <BtnModalShare clickModalHandler={this.toggleModalSocialButton} />} */}
           
             {/* {this.state.copied === true ? <span className="copied-text temporary">Copied Link!</span> : '' } */}
          </div>
          
          <span className="letter-is-for" style={{color:this.props.contentColor}}>{this.props.letter} is for</span>
          <span style={{color:this.props.contentColor}} className="stands-for">{this.props.standsfor}</span>
          {this.props.static ? <><span className="letter-is-for" style={{color:this.props.contentColor}}>FT. {this.props.featureName}</span><span style={{color:this.props.contentColor}} className="feature-info">{this.props.featureTitle}</span><span style={{color:this.props.contentColor}} className="feature-info">{this.props.featureIs}</span> </> : ''}
          <div style={{"maxWidth":"100%", "width":"100%", "margin":"0 auto", "textAlign":'center'}}>
            {this.props.static ? <div className="embed-container"><iframe title={this.props.letter} src={"https://www.youtube.com/embed/"+this.props.youtubeId} frameBorder='0' allowFullScreen></iframe></div> : <img src={images[currentLetter]} alt={this.props.letter} />}
          </div>
          <div style={{color:this.props.contentColor}} className="modal-content-wrapper" dangerouslySetInnerHTML={{__html: copyTextItems[currentLetterSpot].copy}}></div>
          <div className="modal-nav">
            <a href="#" className="fancy-prev" data-fancybox-prev><span>&#60;</span> Previous Letter</a>
            <a href="#" className="fancy-next" data-fancybox-next>Next Letter <span>&#62;</span></a>
          </div>
          
      </div>  
      </>
    )
  }
}
export default ModalItem;


 //{this.props.video ? <PlayBtn /> : <PlusBtn />}