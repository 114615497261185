import React from 'react';
import './style.scss';

import fundingImg from '../../assets/images/funding.png';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="footer-wrapper">
          <div className="footer-column">
          <p>© 2021 YWCA Metro Vancouver</p>
        </div>
        <hr />
        <div className="footer-column footer-second">
          <p>YWCA Metro Vancouver operates on the ancestral and unceded territories of the xʷməθkʷəy̓əm (Musqueam), Sḵwx̱wú7mesh (Squamish) and səl̓ílwətaʔɬ/Selilwitulh (Tsleil-Waututh) Nations. We also acknowledge the unceded territories of Semiahmoo and the Stó:lō peoples, including the Qayqayt, Kwantlen, Katzie and Kwikwetlem Nations, as well as the treaty lands of the Tsawwassen Nation.</p>
        </div>
        <hr />
        <div className="footer-column">
         <p><img className="funding" src={fundingImg} alt="Financial contribution from the Public Health Agency of Canada" /></p>
        </div>
      </div>
     </footer>
    )
  }
}
export default Footer;