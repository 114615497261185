import React from 'react';
import './style.scss';

class BtnPlay extends React.Component {
  render(props) {
    
   
    return (
      <svg className="plus-btn" width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
        <g id="Final-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="plus-btn-shape" transform="translate(-463.000000, -1482.000000)" strokeWidth="2">
            <g id="Letters" transform="translate(74.000000, 913.000000)">
              <g id="A" transform="translate(2.000000, 0.000000)">
                <g id="Group-3" transform="translate(388.000000, 570.000000)">
                    <circle id="Oval" cx="20" cy="20" r="20"></circle>
                    <line x1="19.5" y1="7.5" x2="19.5" y2="32.5" id="Line-2" strokeLinecap="square"></line>
                    <line x1="7" y1="20" x2="32" y2="20" id="Line-2-Copy" strokeLinecap="square"></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
export default BtnPlay;