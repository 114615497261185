import React from 'react';
import './style.scss';

class BtnClose extends React.Component {
  render() {
    return (
      <a href="#" onClick={this.props.clickHandler}>
        <svg className="btn-close" viewBox="0 0 22 22" width="35px" height="35px" version="1.1">
          <g id="Final-Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g id="Interaction-Home" transform="translate(-1793.000000, -198.000000)" stroke="#000000" strokeWidth="2">
                <g id="Group" transform="translate(1613.000000, 41.000000)">
                    <g id="Group-2" transform="translate(181.000000, 158.000000)">
                        <line x1="14.35" y1="0.35" x2="0.35" y2="15.05" id="Line"></line>
                        <line x1="14.35" y1="0.35" x2="0.35" y2="15.05" id="Line-Copy" transform="translate(7.350000, 7.700000) scale(-1, 1) translate(-7.350000, -7.700000) "></line>
                    </g>
                </g>
            </g>
          </g>
        </svg>
      </a>
    )
  }
}
export default BtnClose;