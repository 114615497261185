import React from 'react';
import { Fade } from "react-awesome-reveal";

import './style.scss';

class BtnMobileShare extends React.Component {
  render(props) {
    return (
       <Fade triggerOnce="true"><a href="#" onClick={this.props.clickHandler}>
      <svg className="btn-mobile-share" width="30px" height="30px" viewBox="0 0 40 40" version="1.1">

        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="YWCA-FASD-DescriptionLightbox" transform="translate(-1761.000000, -200.000000)">
                <g id="Description" transform="translate(75.000000, 95.000000)">
                    <g id="Social-Share" transform="translate(1686.000000, 49.000000)">
                        <g id="Share" transform="translate(1.000000, 57.000000)">
                            <path d="M19.4993091,11.0591 C19.4193187,10.994778 19.3073322,10.9819167 19.2166655,11.0256513 C19.1233461,11.0693859 19.0620066,11.1594305 19.0620066,11.2572013 L19.0620066,14.7640418 C18.0780841,14.7203072 14.8810074,14.771768 12.5398686,16.9484168 C10.6306779,18.7211368 9.79075442,21.4509617 10.0440546,25.0581484 C10.0520618,25.1765067 10.1427284,25.2717021 10.2600531,25.2948492 C10.2760512,25.2974246 10.294702,25.3 10.3107001,25.3 C10.4120267,25.3 10.5053623,25.2434041 10.5506875,25.1533438 C10.5773456,25.0967479 13.3317979,19.6988292 19.0620066,20.378074 L19.0620066,23.9286491 C19.0620066,24.0264199 19.1233461,24.1190556 19.2166655,24.1602148 C19.3073322,24.2039494 19.4193187,24.1910882 19.4993091,24.1267661 L27.5013425,17.7923299 C27.5626657,17.7434445 27.6,17.671412 27.6,17.5942129 C27.6,17.5170296 27.5626657,17.4424216 27.5013425,17.3935362 L19.4993091,11.0591 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
                           
                        </g>
                    </g>
                </g>
            </g>
        </g>
      </svg>
      </a></Fade>
    )
  }
}
export default BtnMobileShare;

