import React from 'react';
import './style.scss';


import Footer from '../../components/Footer/Footer';

const Wrapper = ( {children, pageCurrent}) => {

return (

  <div className={`wrapper ${pageCurrent}`}>
    {children}
    <div className="container">
      <Footer />
    </div>
  </div>
)
}
export default Wrapper;