import React from 'react';
import './style.scss';

class BtnFacebook extends React.Component {
  render(props) {
    return (
     <a href={this.props.fblink} target="_blank" rel="noopener noreferrer"><svg className="fb-icon" fill="#4267B2" xmlns="http://www.w3.org/2000/svg"  viewBox="3.5 3.5 22 22" width="35px" height="35px">  <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M19.181,11h-1.729 C16.376,11,16,11.568,16,12.718V14h3.154l-0.428,3H16v7.95C15.671,24.982,15.338,25,15,25c-0.685,0-1.354-0.07-2-0.201V17h-3v-3h3 v-1.611C13,9.339,14.486,8,17.021,8c1.214,0,1.856,0.09,2.16,0.131V11z"/></svg></a>
    )
  }
}
export default BtnFacebook;

