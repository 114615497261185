import React from 'react';
import { Helmet } from 'react-helmet';

const MetaEn = <Helmet>
              <title>The FASD Dictionary | YWCA Metro Vancouver</title>
              <meta name="description"               content="Learn the basics of Fetal Alcohol Spectrum Disorder." />
              <meta property="og:url"                content="https://fasddictionary.ywcavan.org" />
              <meta property="og:type"               content="website" />
              <meta property="og:title"              content="The FASD Dictionary | YWCA Metro Vancouver" />
              <meta property="og:description"        content="Learn more about Fetal Alcohol Spectrum Disorder, and hear from individuals living with it through the FASD Dictionary." />
              <meta property="og:image"              content="https://fasddictionary.ywcavan.org/i/fb-share-img.jpg" />
              <meta name="twitter:image"             content="https://fasddictionary.ywcavan.org/i/twitter-share-img.jpg" />
            </Helmet>;



const Meta = (props) => {
   let Meta;
    //props.lang === 'fr' ? Meta = MetaFr : Meta = MetaEn;
    if(props.letter === 'A' ) {
      //  Meta = 
      // <Helmet>
      //   <title>The FASD Dictionary - A | YWCA Metro Vancouver</title>
      //   <meta name="description"               content="Description for A" />
      //   <meta property="og:url"                content="https://fasddictionary.ywcavan.org/#fasddictionary-1" />
      //   <meta property="og:type"               content="website" />
      //   <meta property="og:title"              content="OG The FASD Dictionary - A | YWCA Metro Vancouver" />
      //   <meta property="og:description"        content="OG Description for A" />
      //   <meta property="og:image"              content="https://fullpath.com/image.png" />
      // </Helmet>;
 
    } else if(props.letter === 'B') {
      // Meta = 
      //   <Helmet>
      //     <title>The FASD Dictionary - B | YWCA Metro Vancouver</title>
      //     <meta name="description"               content="Description for B" />
      //     <meta property="og:url"                content="https://fasddictionary.ywcavan.org/#fasddictionary-2" />
      //     <meta property="og:type"               content="website" />
      //     <meta property="og:title"              content="OG The FASD Dictionary - B | YWCA Metro Vancouver" />
      //     <meta property="og:description"        content="OG Description for B" />
      //     <meta property="og:image"              content="https://fullpath.com/image.png" />
      //   </Helmet>;
       
    } else {
      Meta = 
      <Helmet>
        <title>The FASD Dictionary | YWCA Metro Vancouver</title>
        <meta name="description"               content="Learn the basics of Fetal Alcohol Spectrum Disorder." />
        <meta property="og:url"                content="https://fasddictionary.ywcavan.org" />
        <meta property="og:type"               content="website" />
        <meta property="og:title"              content="The FASD Dictionary | YWCA Metro Vancouver" />
        <meta property="og:description"        content="Learn more about Fetal Alcohol Spectrum Disorder, and hear from individuals living with it through the FASD Dictionary." />
        <meta property="og:image"              content="https://fasddictionary.ywcavan.org/i/fb-share.jpg" />
        <meta name="twitter:image"             content="https://fasddictionary.ywcavan.org/i/twitter-share.jpg" />
      </Helmet>;
   
    }
    return (
        <>
          {Meta}
        </>
    )
}
export default Meta;