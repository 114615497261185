import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import '../assets/scss/styles.scss';

import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Home from '../pages/Home/Home';

// import About from '../pages/About/About';
// import Contact from '../pages/Contact/Contact';

import Navbar from '../components/Navbar/Navbar';

class App extends React.Component {
  static propTypes = { 
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    const {cookies} = props;
    this.state = {
      // ageCookie: cookies.get('ageGateTest') ? true : false,
    }
  }

  

  render() {
    return (

        <React.Fragment>
            <Router>
              <Switch>
               {/* // <Route exact path="/:lang" component={Home} />
               // <Route exact path="/:lang/home" component={Home} /> */}
                <Route exact path="/" component={Home} />
                <Route path='/A' component={() => { window.location = '../#A'; return null;} }/>
                <Route path='/B' component={() => { window.location = '../#B'; return null;} }/>
                <Route path='/C' component={() => { window.location = '../#C'; return null;} }/>
                <Route path='/D' component={() => { window.location = '../#D'; return null;} }/>
                <Route path='/E' component={() => { window.location = '../#E'; return null;} }/>
                <Route path='/F' component={() => { window.location = '../#F'; return null;} }/>
                <Route path='/G' component={() => { window.location = '../#G'; return null;} }/>
                <Route path='/H' component={() => { window.location = '../#H'; return null;} }/>
                <Route path='/I' component={() => { window.location = '../#I'; return null;} }/>
                <Route path='/J' component={() => { window.location = '../#J'; return null;} }/>
                <Route path='/K' component={() => { window.location = '../#K'; return null;} }/>
                <Route path='/L' component={() => { window.location = '../#L'; return null;} }/>
                <Route path='/M' component={() => { window.location = '../#M'; return null;} }/>
                <Route path='/N' component={() => { window.location = '../#N'; return null;} }/>
                <Route path='/O' component={() => { window.location = '../#O'; return null;} }/>
                <Route path='/P' component={() => { window.location = '../#P'; return null;} }/>
                <Route path='/Q' component={() => { window.location = '../#Q'; return null;} }/>
                <Route path='/R' component={() => { window.location = '../#R'; return null;} }/>
                <Route path='/S' component={() => { window.location = '../#S'; return null;} }/>
                <Route path='/T' component={() => { window.location = '../#T'; return null;} }/>
                <Route path='/U' component={() => { window.location = '../#U'; return null;} }/>
                <Route path='/V' component={() => { window.location = '../#V'; return null;} }/>
                <Route path='/W' component={() => { window.location = '../#W'; return null;} }/>
                <Route path='/X' component={() => { window.location = '../#X'; return null;} }/>
                <Route path='/Y' component={() => { window.location = '../#Y'; return null;} }/>
                <Route path='/Z' component={() => { window.location = '../#Z'; return null;} }/>
                {/* <Route exact path="/:lang/contact" component={Contact} />
                <Route exact path="/:lang/about" component={About} /> */}
              </Switch>
            </Router>
        </React.Fragment>
    )
  }
}
export default withCookies(App);