import React from 'react';
import './style.scss';

class BtnPlay extends React.Component {
  render(props) {
    return (
      <>
   
      <svg version="1.1" className="play-btn" height="42px" width="42px" x="0px" y="0px" viewBox="0 0 42 42" style={{enableBackground:"new 0 0 42 42"}}>
        <g>
          <path className="play-circle" d="M21,1C10,1,1,10,1,21c0,11,9,20,20,20s20-9,20-20C41,10,32,1,21,1z M15,30V13l16,8.5L15,30z"/>
          <polygon className="play-triangle" points="15,30 31,21.5 15,13 	"/>
        </g>
      </svg>



{/* <svg className="play-btn" width="42px" height="42px" version="1.1" x="0px" y="0px"
	 viewBox="0 0 42 42" style={{enableBackground:'new 0 0 42 42'}}>

<g id="Final-Pages">
	<g className="play-item" transform="translate(-938.000000, -2817.000000)">
		<g id="Letters" transform="translate(74.000000, 913.000000)">
			<g id="F" transform="translate(591.000000, 1398.000000)">
				<g className="shape" transform="translate(274.000000, 507.000000)">
					<g id="Oval">
						<path className="st0 circle" d="M20,40.5C8.7,40.5-0.5,31.3-0.5,20S8.7-0.5,20-0.5S40.5,8.7,40.5,20S31.3,40.5,20,40.5z M20,0.5
							C9.2,0.5,0.5,9.2,0.5,20c0,10.8,8.7,19.5,19.5,19.5c10.8,0,19.5-8.7,19.5-19.5C39.5,9.2,30.8,0.5,20,0.5z"/>
					</g>
					<g id="Rectangle">
						<path className="st0 polygon" d="M13.5,29.8V11.2l17.6,9.3L13.5,29.8z M14.5,12.8v15.3l14.4-7.7L14.5,12.8z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
</svg> */}
</>
    )
  }
}
export default BtnPlay;

