import React from 'react';
import './style.scss';

class BtnLink extends React.Component {
  render() {
    return (
    
      <a href="#" onClick={this.props.clickHandler} target="_blank" rel="noopener noreferrer">
      <svg className="link-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="3.5 4.5 40 40" width="35px" height="35px"><circle cx="24" cy="24" r="20" fill="#E0DFD9"/><path fill="#000" d="M19.757,26.828l7.071-7.071l1.414,1.414l-7.071,7.071L19.757,26.828z"/><path fill="#000" d="M24.707,16.222c-0.503,0.503-2.325,2.325-2.828,2.828l1.414,1.414	c0.546-0.546,2.283-2.283,2.828-2.828c1.172-1.172,3.071-1.172,4.243,0s1.172,3.071,0,4.243c-0.546,0.546-2.283,2.283-2.828,2.828	l1.414,1.414c0.503-0.503,2.325-2.325,2.828-2.828c1.952-1.952,1.952-5.119,0-7.071S26.659,14.27,24.707,16.222z M23.293,31.778	c0.503-0.503,2.325-2.325,2.828-2.828l-1.414-1.414c-0.546,0.546-2.283,2.283-2.828,2.828c-1.172,1.172-3.071,1.172-4.243,0	s-1.172-3.071,0-4.243c0.546-0.546,2.283-2.283,2.828-2.828l-1.414-1.414c-0.503,0.503-2.325,2.325-2.828,2.828	c-1.952,1.952-1.952,5.119,0,7.071S21.341,33.73,23.293,31.778z"/></svg></a>
  
    )
  }
}
export default BtnLink;