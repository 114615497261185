import React from 'react';
import { Fade } from "react-awesome-reveal";

import './style.scss';

class BtnShare extends React.Component {
  render() {
    return (
      <>
        <Fade triggerOnce="true"><a className="share-social" href="#" onClick={this.props.clickHandler}>Share on Social</a></Fade>
      </>
    )
  }
}
export default BtnShare;