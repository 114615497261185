export const data=[
  {
    letter: 'A',
    copy: `
    <p>An assessment helps identify a person's individual strengths, abilities, and needs. The information learned through an assessment identifies what supports a person with FASD may require at home, in school, and in the community.</p>
 
    <p>In Canada, standardized assessments follow national consensus guidelines for diagnosis of FASD across the lifespan. In BC, families can complete an assessment through provincial and regional health services’ Complex Developmental Behavioural Conditions (CDBC) Network including <a target="_blank" style="color: rgb(23, 43, 244);" rel="noreferrer" href="http://www.bcchildrens.ca/our-services/sunny-hill-health-centre/our-services/complex-developmental-behavioural-conditions#Your--team">Sunny Hill Health Centre for Children</a> and the <a style="color: rgb(23, 43, 244);" target="_blank" rel="noreferrer" href="https://www.asantecentre.org/">Asante Centre</a>. <a style="color: rgb(23, 43, 244);" href="https://www.fasdokanagan.ca/assessment-and-diagnosis/" target="_blank" rel="noreferrer">The FASD Okanagan Valley Assessment and Support Society</a> supports older youths and adults.</p>
     
    <p><i>“Knowing what my child needs for school has helped me advocate for changes in the classroom.”</i><br /><br />
     For more information see Canada’s Fetal alcohol spectrum disorder: a guideline for diagnosis across the lifespan <a style="color: rgb(23, 43, 244);" target="_blank" rel="noreferrer" href="https://www.cmaj.ca/content/188/3/191">here</a>.</p>`
  },
  {
    letter: 'B',
    copy: `
    <p>
    Prenatal alcohol exposure (PAE) can affect brain structure or function, which can lead to a wide range of primary and secondary disabilities described in FASD.</p>

    Ten primary areas (domains) of brain effects are: 
    <ol>
      <li>Neuroanatomy and neurophysiology</li>
      <li>Motor skills</li>
      <li>Language</li>
      <li>Cognition</li>
      <li>Academic achievement</li>
      <li>Memory</li>
      <li>Attention</li>
      <li>Executive functions</li>
      <li>Mood regulation</li>
      <li>Adaptive behaviour, social and communication skills</li>
    </ol>

    <p>Secondary disabilities (outcomes) may occur later in life, as a result of primary disabilities associated with FASD. These often result from lack of supportive health, education, legal and social services systems and include disrupted school experiences, mental health issues and substance use.</p>

    <p>For more information on how a person’s brain is affected by FASD, see FASD Hub Australia’s <a target="_blank" style="color: rgb(51, 128, 112);" rel="noopener noreferrer" href="https://www.fasdhub.org.au/fasd-information/understanding-fasd/what-is-fasd/brain-impairment-in-fasd/">interactive map</a> and <a style="color: rgb(51, 128, 112);" target="_blank" rel="noopener noreferrer" href="https://www.cmaj.ca/content/188/3/191">Canada’s Fetal alcohol spectrum disorder: a guideline for diagnosis across the lifespan</a>.
    </p>
    `
  },
  {
    letter: 'C',
    copy: 
    `<p>Cognition refers to the many ways our brains make sense of things. It’s the process of understanding, knowing, and learning new things.</p>

    <p>The way a person thinks, remembers, and problem-solves can be affected by FASD. Most people with FASD do not have an intellectual disorder, but they can experience challenges adapting to new or changing environments. </p> 
    
    <p>Understanding our different learning and knowing styles can help individuals, family, friends, teachers, and employers create more personalized supports.</p>
    `
  },
  {
    letter: 'D',
    copy: `
    <p>FASD is an umbrella term for diagnostic findings that represent the spectrum of effects caused by alcohol exposure before birth. These differences can affect each person in different ways, and can range from mild to severe.</p>
 
    <p>A medical and/or functional diagnosis helps the individual and their family, friends, and wider community better understand the needs of persons with FASD.</p>
 
    <p>A diagnosis may also contribute to supporting families and preventing alcohol exposure in future pregnancies by increasing awareness about prenatal alcohol exposure.</p>

    To diagnose FASD, doctors and psychologists assess three major areas:
    <ol>
    <li>Brain and nervous system issues.</li>
    <li>Lower-than-average height, weight, or both.</li>
    <li>Characteristic subtle facial features (e.g., smaller eye openings, thin upper lip, and smooth ridge between nose and lip).</li>
    </ol>

    <p>*Note: In the absence of known prenatal alcohol exposure, a diagnosis may be possible if all these are present and other causes can be excluded.</p>
    `
  },
  {
    letter: 'E',
    copy: `
    <p>FASD is a lifelong, whole-body condition. Earlier diagnosis and support can make a positive impact to improve health and education for people with FASD.</p>

    <p>People with FASD require different supports as they age. For adults, eligibility for funding an FASD diagnosis and qualifying for supports pose major barriers. We can all advocate for increased funding for adults to access diagnosis and supports.</p>
    `
  },
  {
    letter: 'F',
    copy: `
    <p>FASD is Fetal Alcohol Spectrum Disorder. It is a lifelong, whole-body condition with a wide range of effects.</p>
 
    <p class="content-quote"><span class="content-curly">“</span>FASD can look like different things in different people – that’s why we call it a spectrum<br />
    <span class="content-signed"><span class="dash">————</span>  DR. PREETY SALH, DEVELOPMENTAL PEDIATRICIAN</span></p>
    
    <p class="content-quote"><span class="content-curly">“</span>I often refer to FASD as <i>Family Adversity Stress/System Disorder</i> to highlight how toxic stress contributes to FASD, long before pregnancy and impacts the whole family due to systemic gaps in mental health and social supports. <br /><span class="content-signed"><span class="dash">————</span> Dr. Chris Loock</span></p>
    `
  },
  {
    letter: 'G',
    copy: `
    <p>Everyone of us has strengths and contributes to society in different ways. While people with FASD may require extra support, we recognize their strengths and contributions and our role to provide them with the supports that they want and need to contribute.</p>
    `
  },
  {
    letter: 'H',
    copy: `
    <p>FASD is a lifelong, whole-body condition.</p>
 
    <p>Adults with FASD often have longer-term challenges with their health and well-being.</p>

    <p>With responsive informed primary health care providers and access to diagnosis, supports for education, employment, and inclusion, and advocacy from people they trust, people with FASD can lead more healthy lives.</p>
    `
  },
  {
    letter: 'I',
    copy: `
    <p class="content-quote"><span class="content-curly">“</span>We're all people first.<br />
    <span class="content-signed"><span class="dash">————</span>  KATRINA, INDIVIDUAL WITH FASD</span></p>
    `
  },
  {
    letter: 'J',
    copy: `
    <p>For a variety of social and health factors, people with FASD are often over-represented within child welfare and youth and adult justice settings.</p>
 
    <p>Everyone working with individuals in child welfare and juvenile and adult justice settings should collaborate with medical, mental health, and disability services, and adopt policies and practices that acknowledge the root causes of FASD and better assist people with the disability.</p>
 
    <p>Decision-makers must invest in evidence-based research to better understand FASD and enact policies that reduce social and health inequalities. In Canada, the <a target="_blank" rel="noreferrer" style="color: rgb(181, 35, 0);" href="http://trc.ca/assets/pdf/Calls_to_Action_English2.pdf">Truth and Reconciliation Commission</a> (TRC) Calls to Action # 33 & 34 highlights injustice associated with FASD and calls on all levels of government to address and prevent FASD by making changes to health, social and justice systems.</p>`
  },
  {
    letter: 'K',
    copy: `
    <p class="content-quote"><span class="content-curly">“</span>Key worker support should be low barrier because it takes a lot of courage for an individual to walk into an agency and ask for supports.<br />
    <span class="content-signed"><span class="dash">————</span>  WANDA PELLETIER, KEY SUPPORT WORKER</span></p>
    `
  },
  {
    letter: 'L',
    copy: `
    <p>People with FASD benefit from individualized supports and learning strategies.</p>
 
    <p>Family, caregivers, friends, teachers, and professionals can support people with FASD to thrive in adapted learning environments. Successful strategies can include using concrete and plain language, being consistent, creating structure and routines, and focusing on special strengths and interests.</p>
`
  },
  {
    letter: 'M',
    copy: `
    <p>People with FASD often experience difficulty remembering or recalling new things. In FASD, ‘working memory’ is often more affected than long term memories.  Like a ‘mental’ sticky note, our ‘working memory’ reminds us of short term information to use later and helps us with new learning.</p>

    <p>Ways to help people with FASD understand and remember things include using clear, plain language, visuals, agendas, and other reminders like sticky notes or lists, and sharing these strategies with family, friends, and support teams to work collaboratively.</p>
    `
  },
  {
    letter: 'N',
    copy: `
    <p>Prenatal alcohol exposure can change the function of many parts of our brains. People with FASD have a range of neurodevelopmental diversity, including challenges with communication and verbal language, memory, learning, motor skills, and executive function. This complex neurodevelopmental disorder is often missed or misdiagnosed and may be seen with other conditions including ADHD, Autism, Attachment Disorder, Language Disorder, PTSD, Sensory Processing and Sleep Disorders. </p>

    <p><a href="#B" onclick="Fancybox.getInstance().destroy().prev();return false">See the Letter B for more details on the Brain!</a></p>
    `
  },
  {
    letter: 'O',
    copy: `
    <p>Exposure to places and situations can be overwhelming for people with FASD. Different textures, loud noises, bright lights, and other unexpected sensory stimuli can be unwanted and unpleasant. It sometimes feels like having “brain fog”. This may lead to people with FASD feeling overwhelmed when faced with complex or even everyday activities, like walking into a crowded room, being on a noisy bus or caught in a traffic jam.</p>
 
    <p>Support people with FASD by helping them to develop strategies that work for them and by making adaptations to help them control their environments.</p>

    <p>
    <strong>Activity:</strong>
    </p>

    <p>The way a person thinks and problem-solves can be impacted by FASD. Here's a simple activity to help you understand what this can feel like.</p>

    <ol>
    <li>Count from 1-10 and then stand up.</li>
    <li>Now, arrange the numbers 1-10 in alphabetical order and then sit down.</li>

    <p>Did you give up? This is a powerful example of what it can feel like to feel <strong>overwhelmed</strong>. It also demonstrates the resilience and determination required to walk through life with FASD.</p>

    <p><sup>*</sup>Answers:<br />Eight, Five, Four, Nine, One, Seven, Six, Ten, Three and Two</p>

`
  },
  {
    letter: 'P',
    copy: `
    <p><a style="color: rgb(23, 27, 39);" href="https://canfasd.ca/wp-content/uploads/publications/FASD-as-a-Unique-Disability-Issue-Paper-FINAL.pdf" target="_blank" rel="noreferrer">FASD has causes that are biological and social</a>. FASD can be prevented by avoiding alcohol. With good medical advice and non-judgmental support, women can have healthy pregnancies.</p>

    <p>As a community, we must all understand the risks of drinking alcohol during pregnancy. Prevention also includes addressing root causes, helping mitigate adverse childhood events, and identifying protective childhood experiences when working with people who have FASD or are at risk of prenatal alcohol exposure.</p>
`
  },
  {
    letter: 'Q',
    copy: `
    <p>Myth: Drinking in moderation won’t cause FASD.</p>
    <p>Fact: During pregnancy, there is no known amount of alcohol or period of time that is considered absolutely ‘safe’ to drink.</p>
    
    <p>Myth: Behavior is a choice. People with FASD just need to try harder.</p>
    
    <p>Fact: It is not about choosing to behave. The neurobehavioural brain differences associated with FASD can make it difficult to understand or process quickly to make choices. That’s what executive function is all about. </p>

    <p><a href="#X" onclick="Fancybox.getInstance().destroy().prev();return false">See the Letter X for Executive Function.</a></p> 

    <p>Myth: FASD only affects children and adolescents. Adults don’t have it.</p>

    <p>Fact: No! FASD is a lifelong condition that one does not “outgrow”. People with FASD face greater challenges as they get older because society sees FASD as  behavioural without recognizing it as an “invisible” disability.</p>
    
    <p>Myth: FASD only affects poor people.</p>
    
    <p>Fact: FASD is the result of prenatal exposure to alcohol. It affects people of ALL classes, races, ages, cultures, genders and sexualities.</p>
    
    <p>Myth:  Teachers, doctors, employers and others are educated on supporting people with FASD.</p>
    
    <p>Fact: No, not enough teachers, doctors, employers, and others have the knowledge, experience, or tools to support people with FASD.</p>
    `
  },
  {
    letter: 'R',
    copy: `
    <p class="content-quote"><span class="content-curly">“</span>Surrounding myself with good, positive people helped me live a good, positive life.<br /> <span class="content-signed"><span class="dash">————</span>  MYLES, INDIVIDUAL WITH FASD</span></p>
    `
  },
  {
    letter: 'S',
    copy: `
    <p>People with any type of disability or diversity often face stigma and negative stereotypes.</p>
    
    <p>Because FASD is largely an invisible condition, society may fail to understand and appreciate the challenges a person with FASD experiences. Stigma can lead to social isolation and a range of physical and mental health issues.</p>
    
    <p>Mothers of children with FASD also face additional stigma. Fear of judgment is one of the reasons that women may not disclose to health care providers or others that they consumed alcohol during their pregnancy. </p>

    <p>Fear that a child might be excluded or stigmatized worries all parents.</p>
    
    <p>Fighting stigma is our collective responsibility. Teachers, health care providers, employers, and the wider community can play an important role in ending stigma.</p>
    `
  },
  {
    letter: 'T',
    copy: `
    The transition to adulthood can be challenging for anyone, so too for people with FASD. Many support services for adolescents with FASD are cut off once a youth  reaches adulthood. Expectations also become higher.
 
    <p>A strong, supportive network and the right accommodations, such as repetition and routine, and <a style="color: rgb(186, 41, 27);" href="http://www.bcchildrens.ca/transition-to-adult-care/Documents/ON%20TRACBackground2011.pdf" target="_blank" rel="noreferrer">planning early</a>, all help improve the transition to adulthood.</p>`
  },
  {
    letter: 'U',
    copy: `
    <p>There is no typical person with FASD. FASD is heterogeneous. FASD is universal. FASD occurs in all parts of society where alcohol is consumed.
    </p>`
  },
  {
    letter: 'V',
    copy: `
    <p>We all learn in different ways. People with FASD often excel in more non-verbal, visual learning settings, with help from images, pictures, colours, and other media.</p> 

    <p>People with FASD sometimes describe themselves as “see-ers and do-ers” rather than “talkers and thinkers” and tend to be better at “hands-on” learning experiences. </p>
    `
  },
  {
    letter: 'W',
    copy: `
    <p>People with FASD have many strengths, skills, and abilities they bring into a workplace, if trained, supported, and accommodated.</p>
 
    <p> When provided education, training and opportunity, people with FASD can and do find their purpose and and have opportunities to experience employment success. For example, some people with FASD may thrive in more experiential training environments.</p>
    `
  },
  {
    letter: 'X',
    copy: `
    <p>Executive function refers to a set of skills involved in organizing, planning, shifting attention, and focus.</p>
 
    <p>People with FASD may have difficulties with executive functions like planning tasks or moving from one activity or environment to another.</p>
    `
  },
  {
    letter: 'Y',
    copy: `
    <p class="content-quote"><span class="content-curly">“</span>We need people to be okay with us in order for us to be okay with ourselves.<br /> <span class="content-signed"><span class="dash">————</span>  CJ, INDIVIDUAL WITH FASD</span></p>
    `
  },
  {
    letter: 'Z',
    copy: `
    <p>Just as no two zebras have the same stripe pattern, no two people with FASD have the same experiences, gifts, or interests. We are all different. Vive la difference!</p>
    `
  }
]