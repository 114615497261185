import React from 'react';
//import {Link, NavLink} from 'react-router-dom';
import {LanguageConsumer} from '../../providers/LanguageProvider';

// import ywcaLogo from '../../assets/images/logo.png';
// import ShareBtn from '../Btns/BtnShare/BtnShare';
// import BtnFacebook from '../Btns/BtnFacebook/BtnFacebook';
// import BtnTwitter from '../Btns/BtnTwitter/BtnTwitter';
// import BtnLink from '../Btns/BtnLink/BtnLink';
// import BtnClose from '../Btns/BtnClose/BtnClose';
import './style.scss';


class Navbar extends React.Component {
  render() {
    return (
      <LanguageConsumer>
        {(context) => (
         <>
         </>
        )}
      </LanguageConsumer>


    )
  }
}
export default Navbar;