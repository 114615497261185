import React from 'react';
import { isDesktop } from '../../helpers';
import './style.scss';

import Wrapper from '../../templates/Wrapper/Wrapper';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';

import ywcaLogo from '../../assets/images/logo.png';

import Tilt from 'react-parallax-tilt';
import sunBlur from '../../assets/images/lightblur.png';

import BtnShare from '../../components/Btns/BtnShare/BtnShare';
import BtnFacebook from '../../components/Btns/BtnFacebook/BtnFacebook';
import BtnTwitter from '../../components/Btns/BtnTwitter/BtnTwitter';
import BtnLink from '../../components/Btns/BtnLink/BtnLink';
import BtnClose from '../../components/Btns/BtnClose/BtnClose';

import BtnMobileShare from '../../components/Btns/BtnMobileShare/BtnMobileShare';

import Meta from '../../components/Meta/Meta';
import { Fade, Slide } from "react-awesome-reveal";
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import arrowBounce from '../../assets/images/arrow-bounce.png';

import GridSingleItem from '../../components/GridSingleItem/GridSingleItem';
//import ModalContent from '../../components/ModalContent/ModalContent';
import AboutSection from '../../components/AboutSection/AboutSection';


//element.addEventListener("tiltChange", callback);

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLetter: '',
      homeSocialToggle: false,
      footerSocialToggle: false,
      copied: false,
      footerCopied:false,
      modalOpen: false,
      isDesktop: isDesktop() ? true : false
    };

    this.toggleSocialButton = this.toggleSocialButton.bind(this);
    this.toggleFooterSocialButton = this.toggleFooterSocialButton.bind(this);
    this.copyUrl = this.copyUrl.bind(this);
  }


  toggleSocialButton(e) {
    e.preventDefault();
    this.setState(prevState => ({ homeSocialToggle: !prevState.homeSocialToggle }))
  }

  toggleFooterSocialButton(e) {
    e.preventDefault();
    this.setState(prevState => ({ footerSocialToggle: !prevState.footerSocialToggle }))
  }


  componentDidMount = () =>{
    window.addEventListener('resize', this.handleResize);
    
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    let viewPort = isDesktop();
    if (viewPort !== this.state.isDesktop){
        this.setState(prevState => ({
           isDesktop: viewPort
          }));
    }
  }

  copyUrl = (event) => {
    event.preventDefault();
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    this.setState(state => ({
      copied: true
    }));
    document.body.removeChild(el);
  }

  render() {
    
    Fancybox.bind("[data-fancybox]", {
      closeButton:'outside',
      preload:0,
      dragToClose: false,
      on: {
        ready : (fancybox) => {
        //      console.log(`fancybox #${fancybox.id} is ready!`);
        },
        "*": (event, fancybox, slide) => {
       //   console.log(`event: ${event}`);
        },
        "Carousel.selectSlide": (fancybox, carousel, slide) => {
      //     console.log( slide.index);
      console.log('select slide');
      //    var slideElement = document.querySelector('.fancybox__slide');
      // slideElement.classList.add('hide-scroll');
         //  setMeta(this.props.letter);
         var slideElement = document.querySelectorAll('.fancybox__slide');
         console.log(slideElement);
        // slideElement.classList.add('hide-scroll');
         for (var i = 0; i < slideElement.length; i++) {
           slideElement[i].classList.add('hide-scroll');
         };
        },
        "Carousel.change": (fancybox, carousel, slide) => {
        //  console.log( slide.index);
        console.log('change');
          // var slideElement = document.querySelectorAll('.fancybox__slide');
          // for (var i = 0; i < slideElement.length; i++) {
          //   slideElement[i].classList.add('hide-scroll');
          // };
        
        },
        "Carousel.Panzoom.click": (fancybox, carousel, panzoom, e) => {
          console.log('test click');
          if ("fancyboxPrev" in e.target.dataset) {
            e.stopPropagation();
            e.preventDefault();
    
            fancybox.prev();
          }
    
          if ("fancyboxNext" in e.target.dataset) {
            e.stopPropagation();
            e.preventDefault();
    
            fancybox.next();
          }
        },
        "Carousel.settle": (fancybox, carousel, slide) => {
          console.log('settled');
          var slideElement = document.querySelector('.fancybox__slide');
          slideElement.classList.remove('hide-scroll');
        },
        done: (fancybox, slide) => {

        // console.log('slide: ', slide.index);
        // changes number to letter (0=A, 1=B, 2=C, etc)
         var numberToLetter = (slide.index+10).toString(36).toUpperCase();

        //  document.write((value + 9).toString(36).toUpperCase());

         this.setState(state => ({
          currentLetter: numberToLetter
        }));
      
        }
      },
      Carousel: {
        Navigation: {
          prevTpl: '<svg data-name="Layer 1" viewBox="0 0 185 42"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:none;stroke:#000;}</style></defs><path class="cls-1" d="M21,0H164a21,21,0,0,1,21,21h0a21,21,0,0,1-21,21H21A21,21,0,0,1,0,21H0A21,21,0,0,1,21,0Z"/><path class="cls-2" d="M26,15l-6,6,6,6"/><path d="M39.82,27V23.74h3a3.2,3.2,0,1,0,0-6.4H38.49V27Zm3-5h-3v-3h3c1.16,0,1.95.6,1.95,1.5S44,22,42.81,22Zm6.36,5V23.74h2.47L54,27H55.6l-2.39-3.4a3.12,3.12,0,0,0,2.28-3.05,3.17,3.17,0,0,0-3.28-3.21H47.84V27Zm3-5h-3v-3h3c1.16,0,1.95.6,1.95,1.5S53.33,22,52.17,22Zm12.1,5V25.29H58.69V23h5.05V21.32H58.69V19.05H64.2V17.34H57.36V27Zm6.8,0,3.7-9.66H73.21l-3,7.63h-.32l-3-7.63H65.39L69.08,27Zm6.6,0V17.34H76.34V27Zm7,.17a5,5,0,1,0-5.08-5A4.94,4.94,0,0,0,84.66,27.17Zm0-1.75A3.41,3.41,0,0,1,81,22.17a3.73,3.73,0,0,1,7.39,0A3.41,3.41,0,0,1,84.66,25.42Zm10.76,1.75a3.85,3.85,0,0,0,4-3.89V17.34H98.07v5.91a2.69,2.69,0,0,1-5.28,0V17.34H91.46v5.94a3.84,3.84,0,0,0,4,3.89Zm9.5,0c2.11,0,3.81-1.18,3.81-3s-1.46-2.54-3.46-2.87c-.81-.13-2.66-.48-2.66-1.38,0-.7,1-1.14,2.2-1.14s2.35.54,2.35,1.26h1.39c0-1.63-1.57-2.89-3.7-2.89s-3.61,1.14-3.61,2.86,1.38,2.53,3.26,2.85c1.19.2,2.86.51,2.86,1.45,0,.72-1.07,1.21-2.37,1.21-1.47,0-2.56-.6-2.56-1.38H101C101,25.88,102.71,27.17,104.92,27.17ZM120.4,27V25.29h-4.88V17.34h-1.33V27Zm8.65,0V25.29h-5.59V23h5.06V21.32h-5.06V19.05H129V17.34h-6.85V27Zm5.82,0V19.05h3.2V17.34h-7.74v1.71h3.21v8Zm8.89,0V19.05H147V17.34h-7.74v1.71h3.2v8Zm11.87,0V25.29H150V23h5.05V21.32H150V19.05h5.52V17.34h-6.85V27Zm3.24,0V23.74h2.46L163.66,27h1.63l-2.39-3.4a3.12,3.12,0,0,0,2.28-3.05,3.17,3.17,0,0,0-3.27-3.21h-4.37V27Zm3-5h-3v-3h3c1.17,0,1.95.6,1.95,1.5S163,22,161.86,22Z"/></svg>',
          nextTpl: '<svg data-name="Layer 2" viewBox="0 0 185 42"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:none;stroke:#000;}</style></defs><path class="cls-1" d="M21,0H164a21,21,0,0,1,21,21h0a21,21,0,0,1-21,21H21A21,21,0,0,1,0,21H0A21,21,0,0,1,21,0Z"/><path class="cls-2" d="M149.5,15.43l7,6.07-7,6.07"/><path d="M45.85,27V20.13h.24l5,6.87h1.58V17.34H51.38v6.87h-.25l-5-6.87H44.52V27ZM62,27V25.29H56.45V23h5.06V21.32H56.45V19.05H62V17.34H55.12V27Zm2.74,0,2.7-3.65h.36L70.53,27h1.55l-3.33-4.82,3.35-4.84H70.42L67.77,21h-.36l-2.63-3.63H63.22l3.28,4.77L63.1,27Zm12.84,0V19.05h3.21V17.34H73.08v1.71h3.21v8ZM92.4,27V25.29H87.52V17.34H86.19V27Zm8.65,0V25.29H95.46V23h5.06V21.32H95.46V19.05H101V17.34H94.13V27Zm5.82,0V19.05h3.2V17.34h-7.74v1.71h3.21v8Zm8.89,0V19.05H119V17.34h-7.74v1.71h3.2v8Zm11.87,0V25.29H122V23h5V21.32h-5V19.05h5.52V17.34h-6.85V27Zm3.24,0V23.74h2.46L135.66,27h1.63l-2.39-3.4a3.12,3.12,0,0,0,2.28-3.05,3.17,3.17,0,0,0-3.27-3.21h-4.37V27Zm3-5h-3v-3h3c1.17,0,1.95.6,1.95,1.5S135,22,133.86,22Z"/></svg>'
        }
      }
    })

    window.Fancybox = Fancybox;
    //Fancybox.defaults.Hash = false;
   

    return (
     
      <Wrapper pageCurrent="home">
        <Meta letter={this.state.currentLetter} />
        <ContainerContent>
        <div className="header">
          <div className="navbar">
            <Fade triggerOnce="true"><a target="_blank" rel="noopener noreferrer" href="https://ywcavan.org/"><img style={{height:'auto'}}src={ywcaLogo} alt="logo" /></a></Fade>
              <div className="social-bar">
                
                {this.state.homeSocialToggle === true ? <Slide direction="right" triggerOnce="true"><Fade delay="30" triggerOnce="true"><span className="social-btns"><BtnFacebook fblink="https://www.facebook.com/sharer.php?u=https://fasddictionary.ywcavan.org/" /><BtnTwitter twitlink="https://twitter.com/intent/tweet?url=https://fasddictionary.ywcavan.org/&text=Learn%20the%20basics%20of%20Fetal%20Alcohol%20Spectrum%20Disorder." /><BtnLink clickHandler={this.copyUrl} /> <BtnClose clickHandler={this.toggleSocialButton} /></span></Fade></Slide> : this.state.isDesktop === false ? <BtnMobileShare clickHandler={this.toggleSocialButton} /> : <BtnShare clickHandler={this.toggleSocialButton} /> }
              
                {this.state.copied === true ? <span className="copied-text temporary">Copied Link!</span> : '' }
              </div>                     
            </div>

            <Fade style={{zIndex:'100', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width:'100%'}} delay="50" triggerOnce="true">
              <h1>
                The FASD Dictionary
              </h1>
            </Fade>
           
            <Slide direction="up" triggerOnce="true" delay="100" className="sun-wrap" >
            
              <Tilt perspective={900}
                reset={false}
                //glareEnable={true}
              //  glareMaxOpacity={0.75}
              //  glarePosition="all"
                //scale={1.02}
                trackOnWindow={true}>
              <img src={sunBlur} className="sun-blur" alt=""  />
              </Tilt>
            </Slide>
           
          </div>
          <Fade style={{position:'relative', zIndex:'100'}} delay="120" triggerOnce="true">
              <h2>
                Learn the basics of <br />Fetal Alcohol Spectrum Disorder.
              </h2>
            </Fade>
            <div className="bounce-arrow">
              <img src={arrowBounce} className="bounce-arrow" alt="" />
            </div>
          <div className="grid">
            
            <GridSingleItem gridClass="grid-a" meaning="Assessment" linkhref="A" dataSrc="#content-A" letter="A" bgColor="#EEC0DC" textColor="#172BF4" />
            <GridSingleItem gridClass="grid-b" meaning="Brain" linkhref="B" dataSrc="#content-B" letter="B" bgColor="#FCFBF0" textColor="#338070" />
            <GridSingleItem gridClass="grid-c" meaning="Cognition" linkhref="C" dataSrc="#content-C" letter="C" bgColor="#BCE5E6" textColor="#C40B00" />
           
            <div className="double grid-double-1">
              <div className="quote-wrapper">
                <div className="quote">
                  <span className="curly">“</span><span className="gradient-text">FASD can look like different things in different people – that’s why we call it a&nbsp;spectrum.</span>
                <span className="signed"><span className="dash">————&nbsp;&nbsp;</span> Dr. Preety Salh, Developmental Pediatrician</span>
                </div>
              </div>
            </div>

            <GridSingleItem gridClass="grid-d" meaning="Diagnosis" linkhref="D" dataSrc="#content-D" letter="D" bgColor="#B05656" textColor="#F9F8E6" />
            <GridSingleItem gridClass="grid-e" meaning="Early supports" linkhref="E" dataSrc="#content-E" letter="E" bgColor="#B0FDEC" textColor="#1F3E55" />
            <GridSingleItem gridClass="grid-f" meaning="FASD" linkhref="F" dataSrc="#content-F" letter="F" video={true} videoId="FXwxEjVrUxs" bgColor="#FCFBF0" textColor="#338070" name="ft. Dr. Gurpreet (Preety) Salh" featureName="DR. PREETY SALH" />
            <GridSingleItem gridClass="grid-g" meaning="Gifts" linkhref="G" dataSrc="#content-G" letter="G" bgColor="#E6F5F0" textColor="#B04C78" />
            <GridSingleItem gridClass="grid-h" meaning="Health" linkhref="H" dataSrc="#content-H" letter="H" bgColor="#FFF6F0" textColor="#177C8A" />

            <div className="single grid-single-1">
              <div className="quote-wrapper">
                <div className="quote">
                  <span className="curly">“</span><span className="gradient-text">I like to think of it as someone painting a picture: every painting is going to look different, but every painting is&nbsp;beautiful.</span>
                <span className="signed"><span className="dash">————&nbsp;&nbsp;</span> Katrina, Individual with FASD</span>
                </div>
              </div> 
            </div>

            <GridSingleItem gridClass="grid-i" meaning="Individual" linkhref="I" dataSrc="#content-I" letter="I" video={true} videoId="a2c5FCXfkU8" bgColor="#FCFBF0" textColor="#338070" name="ft. Kat, Individual with FASD" featureName="Kat" />
            <GridSingleItem gridClass="grid-j" meaning="Justice" linkhref="J" dataSrc="#content-J" letter="J" bgColor="#FFC7E6" textColor="#B52300" />
            <GridSingleItem gridClass="grid-k" meaning="Key Worker Program" linkhref="K" dataSrc="#content-K" letter="K" video={true} videoId="fkmMRNhS6Ls" name="ft. Wanda Pelletier, FASD Key Worker" bgColor="#FCFBF0" textColor="#338070" featureName="WANDA" featureTitle="FASD Key Worker with YWCA Crabtree Corner." featureIs="Wanda is Cree, from the Cowessess First Nation in Saskatchewan." />
            <GridSingleItem gridClass="grid-l" meaning="Learning" linkhref="L" dataSrc="#content-L" letter="L" bgColor="#C8E3FA" textColor="#0A3929" />
            <GridSingleItem gridClass="grid-m" meaning="Memory" linkhref="M" dataSrc="#content-M" letter="M" bgColor="#E7BCAA" textColor="#0B0D13" />

            <div className="double grid-double-2">
              <div className="quote-wrapper">
                <div className="quote">
                  <span className="curly">“</span><span className="gradient-text">FASD affects people regardless of socioeconomic status and regardless of&nbsp;race.</span>
                <span className="signed"><span className="dash">————</span> Dr. Preety Salh, Developmental Pediatrician</span>
                </div>
              </div> 
            </div>

            <GridSingleItem gridClass="grid-n" meaning="Neurodevelopmental Disorder" linkhref="N" dataSrc="#content-N" letter="N" bgColor="#FFF0E5" textColor="#A15C44" />
            <GridSingleItem gridClass="grid-o" meaning="Overwhelm" linkhref="O" dataSrc="#content-O" letter="O" bgColor="#8167BF" textColor="#FFFFFF" />
            <GridSingleItem gridClass="grid-p" meaning="Prevention" linkhref="P" dataSrc="#content-P" letter="P" bgColor="#E88665" textColor="#171B27" />
            <GridSingleItem gridClass="grid-q" meaning="Quiz" linkhref="Q" dataSrc="#content-Q" letter="Q" bgColor="#A34F9D" textColor="#DEF8FA" />
            <GridSingleItem gridClass="grid-r" meaning="Relationships" linkhref="R" dataSrc="#content-R" letter="R" video={true} videoId="K8I4axEQG4s" name="ft. Myles, Individual with FASD" bgColor="#FCFBF0" textColor="#338070" featureName="Myles" />
            <GridSingleItem gridClass="grid-s" meaning="Stigma" linkhref="S" dataSrc="#content-S" letter="S" bgColor="#DEE1F5" textColor="#A34276" />

            <div className="single grid-single-2">
              <div className="quote-wrapper">
                <div className="quote">
                  <span className="curly">“</span><span className="gradient-text">The first thing you see when you look up FASD is a list of&nbsp;stereotypes.</span>
                <span className="signed"><span className="dash">————</span> CJ, Individual with FASD</span>
                </div>
              </div> 
            </div>

            <GridSingleItem gridClass="grid-t" meaning="Transition to Adulthood" linkhref="T" dataSrc="#content-T" letter="T" bgColor="#CCE3D8" textColor="#BA291B" />
            <GridSingleItem gridClass="grid-u" meaning="Universal" linkhref="U" dataSrc="#content-u" letter="U" bgColor="#C5BEAA" textColor="#293569"/>
            <GridSingleItem gridClass="grid-v" meaning="Visual Learners" linkhref="V" dataSrc="#content-v" letter="V" bgColor="#F4C8EE" textColor="#006291" />
            <GridSingleItem gridClass="grid-w" meaning="Work" linkhref="W" dataSrc="#content-w" letter="W" hash="test" bgColor="#A7EEE5" textColor="#28292B" />
            <GridSingleItem gridClass="grid-x" meaning="Executive Function" linkhref="X" dataSrc="#content-x" letter="X" bgColor="#FF958C" textColor="#5D2F78" />
            <GridSingleItem gridClass="grid-y" meaning="You" linkhref="Y" dataSrc="#content-y" letter="Y" video={true} videoId="hb_9FYshrzQ" name="ft. CJ, Individual with FASD" bgColor="#FCFBF0" textColor="#338070" featureName="CJ" />
            <GridSingleItem gridClass="grid-z" meaning="Zebra" linkhref="Z" dataSrc="#content-z" letter="Z" bgColor="#FFFDE6" textColor="#2C8256" />

            <div className="single grid-single-3">
              <div className="quote-wrapper">
                <div className="quote">
                  <span className="curly">“</span><span className="gradient-text">Surrounding myself with good, positive people helped me live a good, positive&nbsp;life.</span>
                <span className="signed"><span className="dash">————</span> Myles, Individual with FASD</span>
                </div>
              </div> 
            </div>
            </div> {/* end grid */ }

            <div className="grid-end">
              <h3>
                The FASD Dictionary
              </h3>
              <div className="social-bar">
           
                  {this.state.footerSocialToggle === true ? <Slide direction="down" triggerOnce="true"><Fade delay="30" triggerOnce="true"><span className="social-btns"><BtnFacebook fblink="https://www.facebook.com/sharer.php?u=https://fasddictionary.ywcavan.org/" /><BtnTwitter twitlink="https://twitter.com/intent/tweet?url=https://fasddictionary.ywcavan.org/&text=Learn%20the%20basics%20of%20Fetal%20Alcohol%20Spectrum%20Disorder." /><BtnLink clickHandler={this.copyUrl} /> <BtnClose clickHandler={this.toggleFooterSocialButton} /></span></Fade></Slide> :  <BtnShare clickHandler={this.toggleFooterSocialButton} /> }

                 
                  {this.state.copied === true ? <span className="copied-text temporary">Copied Link!</span> : '' }
                </div>
            
            </div>
            
          {/* modal content */}

          {/* <ModalContent clickNext={this.nextButton} /> */}
     
         
        </ContainerContent>
        <div className="container">
          <AboutSection />
        </div>
       
      </Wrapper>
    )
  }
}
export default Home;